<template>
  <div class="pt-5">
    <v-text-field
      v-model="companyProfile.location1"
      label="住所"
      outlined
      :clearable="!readonly"
      :readonly="readonly"
    ></v-text-field>

    <div class="d-flex">
      <v-select
        v-model="companyProfile.locationProperty1"
        :items="locationCategory1"
        label="開業場所属性1"
        outlined
        :clearable="!readonly"
        :readonly="readonly"
        class="text-filed-attribute flex-grow-0 mr-6"
      ></v-select>

      <v-select
        v-model="companyProfile.locationProperty2"
        :items="locationCategory2"
        label="開業場所属性2"
        outlined
        :clearable="!readonly"
        :readonly="readonly"
        class="text-filed-attribute"
      ></v-select>
    </div>

    <div class="d-flex">
      <v-select
        v-model="companyProfile.locationProperty3"
        :hint="locationProperty3Hint"
        :items="locationCategory3"
        label="開業場所属性3"
        outlined
        persistent-hint
        :clearable="!readonly"
        :readonly="readonly"
        class="text-filed-attribute flex-grow-0 mr-6"
      ></v-select>

      <v-select
        v-model="companyProfile.locationProperty4"
        :items="locationCategory4"
        label="開業場所属性4"
        outlined
        :clearable="!readonly"
        :readonly="readonly"
        class="text-filed-attribute"
      ></v-select>
    </div>

    <v-textarea-with-link
      v-model="companyProfile.locationReason"
      label="開業場所を選んだ理由"
      :readonly="readonly"
      reference="CompanyProfile_locationReason.txt"
      :refDoc="true"
    ></v-textarea-with-link>

    <v-card class="performance-textfiled-area mt-7 mb-7 pb-3">
      <v-card-title>業績</v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.annualSales"
              label="年商" prefix="￥"
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.monthlySales"
              label="月商" prefix="￥"
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.monthlyCustomer"
              label="月間患者数" suffix="人"
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.dailyCustomer"
              label="1日患者数" suffix="人"
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.monthlyNewCustomer"
              label="月間新患数" suffix="人" outlined
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-currency-field
              v-model="companyProfile.cancellationCustomer"
              label="中断患者数" suffix="人"
              :readonly="readonly"
            ></custom-currency-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-percent-field
              v-model="companyProfile.selfFinancedRate"
              label="自費率" suffix="%"
              :readonly="readonly"
            ></custom-percent-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-percent-field
              v-model="companyProfile.recallRate"
              label="リコール率" suffix="%"
              :readonly="readonly"
            ></custom-percent-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-percent-field
              v-model="companyProfile.occupancyRate"
              label="稼働率" suffix="%"
              :readonly="readonly"
            ></custom-percent-field>
          </v-col>
          <v-col sm="4" md="2">
            <custom-percent-field
              v-model="companyProfile.cancellationRate"
              label="キャンセル率" suffix="%"
              :readonly="readonly"
            ></custom-percent-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="performance-textfiled-area pb-3">
      <v-card-title>組織</v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="6" md="4">
            <!-- ドクター人数 -->
            <v-card class="px-4 pt-2 pb-4 rounded-lg" color="blue lighten-5" outlined>
              <v-card-subtitle class="pt-0 pl-1">ドクター人数</v-card-subtitle>
              <v-row justify="center">
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.regularEmployment.organizationNum1"
                    label="正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.nonRegularEmployment.organizationNum1"
                    label="非正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="6" md="4">
            <!-- 歯科衛生士 -->
            <v-card class="px-4 pt-2 pb-4 rounded-lg" color="blue lighten-5" outlined>
              <v-card-subtitle class="pt-0 pl-1">歯科衛生士</v-card-subtitle>
              <v-row justify="center">
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.regularEmployment.organizationNum2"
                    label="正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.nonRegularEmployment.organizationNum2"
                    label="非正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="6" md="4">
            <!-- 歯科助士 -->
            <v-card class="px-4 pt-2 pb-4 rounded-lg" color="blue lighten-5" outlined>
              <v-card-subtitle class="pt-0 pl-1">歯科助士</v-card-subtitle>
              <v-row justify="center">
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.regularEmployment.organizationNum3"
                    label="正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.nonRegularEmployment.organizationNum3"
                    label="非正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="6" md="4">
            <!-- 歯科技工士 -->
            <v-card class="px-4 pt-2 pb-4 rounded-lg" color="blue lighten-5" outlined>
              <v-card-subtitle class="pt-0 pl-1">歯科技工士</v-card-subtitle>
              <v-row justify="center">
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.regularEmployment.organizationNum4"
                    label="正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.nonRegularEmployment.organizationNum4"
                    label="非正規" suffix="人"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col sm="6" md="4">
            <!-- ユニット数 -->
            <v-card class="px-4 pt-2 pb-4 rounded-lg" color="blue lighten-5" outlined>
              <v-card-subtitle class="pt-0 pl-1">ユニット数</v-card-subtitle>
              <v-row justify="center">
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.regularEmployment.organizationNum5"
                    label="治療" suffix="台"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
                <v-col cols="6">
                  <custom-currency-field
                    v-model="companyProfile.nonRegularEmployment.organizationNum5"
                    label="予防" suffix="台"
                    :readonly="readonly"
                  ></custom-currency-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CustomCurrencyField from '@/components/atomic/atoms/CustomCurrencyField';
import CustomPercentField from '@/components/atomic/atoms/CustomPercentField';
import VTextareaWithLink from '@/components/atomic/atoms/VTextareaWithLink.vue';

export default {
  components: {
    CustomCurrencyField,
    CustomPercentField,
    VTextareaWithLink,
  },
  props: ['value', 'readonly'],
  data(){
    return{
      companyProfile: {
        location1: null,
        locationProperty1: null,
        locationProperty2: null,
        locationProperty3: null,
        locationProperty4: null,
        locationReason: null,
        annualSales: null,
        monthlySales: null,
        monthlyCustomer: null,
        dailyCustomer: null,
        monthlyNewCustomer: null,
        cancellationCustomer: null,
        selfFinancedRate: null,
        recallRate: null,
        occupancyRate: null,
        cancellationRate: null,
        regularEmployment: {
          organizationNum1: null,
          organizationNum2: null,
          organizationNum3: null,
          organizationNum4: null,
          organizationNum5: null,
        },
        nonRegularEmployment: {
          organizationNum1: null,
          organizationNum2: null,
          organizationNum3: null,
          organizationNum4: null,
          organizationNum5: null,
        },
      },
      locationCategory1: ['住宅地', 'オフィス街', '繁華街'],
      locationCategory2: ['戸建て', 'ビルテナント１F', 'ビルテナント２F以上', '医療モール', 'ショッピングモール'],
      locationCategory3: ['駅近', '幹線道路', '駅近×幹線道路'],
      locationCategory4: ['新規', '居抜き', '新規×継承', '居抜き×継承', '家族継承'],
      locationProperty3Hint: '【駅近】駅から徒歩5分圏内 【幹線道路】周囲に比較して交通量の多い道路。車・バイク・自転車でのアクセスが主',

    }
  },
  created(){
    if(this.value){
      this.companyProfile = this.value
    }
  },
  methods: {
    //医院に関する情報セクションの未入力チェック
    isEmpty() {
      if(!this.companyProfile) return true

      const profile = this.companyProfile
      let ret = false

      //各質問項目をチェック
      const questionKeys = ['location1', 'locationProperty1', 'locationProperty2', 'locationProperty3', 'locationProperty4', 'locationReason', 'annualSales', 'monthlySales', 'monthlyCustomer', 'dailyCustomer', 'monthlyNewCustomer', 'cancellationCustomer', 'selfFinancedRate', 'recallRate', 'occupancyRate', 'cancellationRate']
      ret = questionKeys.some(i => profile[i])
      if(ret) return false

      //正規雇用
      const questionKeys2 = ['organizationNum1', 'organizationNum2', 'organizationNum3', 'organizationNum4', 'organizationNum5']
      ret = questionKeys2.some(i => profile.regularEmployment[i])
      if(ret) return false

      //非正規雇用
      const questionKeys3 = ['organizationNum1', 'organizationNum2', 'organizationNum3', 'organizationNum4', 'organizationNum5']
      ret = questionKeys3.some(i => profile.nonRegularEmployment[i])
      if(ret) return false

      return true
    },
  },
  watch: {
    value(val){
      this.companyProfile = val
    },
    "companyProfile.inputStatusClient"(val) {
      this.$store.commit('brand_input_status_profile', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.text-filed-attribute{
  width: 50%;
}
</style>
<style lang="scss">
.performance-textfiled-area{
  background-color: #f3faff!important;
  .v-input__slot{
    background-color: white!important;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>